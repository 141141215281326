<template>
  <v-app>
    <v-navigation-drawer
        v-model="drawer"
        app
        bottom
    >

      <app-drawer/>
    </v-navigation-drawer>

    <v-app-bar
        app
        color="white"
        flat
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-container class="py-0 fill-height">
        <v-toolbar-title>
          <v-img v-if="!(drawer && $vuetify.breakpoint.mdAndUp)"
                 max-height="50px"
                 max-width="180px"
                 :src="require('./assets/acampe.me.svg')"
                 alt="logo we-camp">
          </v-img>
        </v-toolbar-title>
        <span class="v-size--x-small text--disabled">(RC4)</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="donate" v-on="on">
              <v-icon>mdi-glass-mug-variant</v-icon>
            </v-btn>
          </template>
          <span>Gostou do App? Pague uma cerveja para o autor!</span>
        </v-tooltip>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-container class="mx-auto pa-2 pa-sm-3">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import AppDrawer from "@/AppDrawer";

export default {
  name: 'App',
  components: { AppDrawer },
  data: () => ({
    drawer: false,
    selectedItem: 1
  }),
  methods: {
    donate () {
      this.$router.push({ name: 'donate' })
    }
  }
};
</script>
